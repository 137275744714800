import React from 'react';
import SEO from './SEO';

const About = () => {
    return (
        <>
            <SEO
                title="About Us - CV Ranking Tool"
                description="Learn about our mission to streamline the initial recruitment screening process through AI-powered CV ranking."
                keywords="about cv ranking, recruitment innovation, AI recruitment, screening candidates"
            />
            <div className="container mx-auto max-w-4xl px-6">
                <div className="bg-white rounded-xl shadow-lg p-12 border border-gray-100
                               md:p-8
                               sm:p-6">
                    <div className="flex items-center mb-8">
                        <div className="w-2 h-12 bg-gradient-to-b from-indigo-600 to-blue-500 rounded-full mr-4" />
                        <h1 className="text-4xl font-bold text-gray-800
                                     sm:text-3xl">
                            About Us
                        </h1>
                    </div>
                    <div className="prose lg:prose-lg max-w-none">
                        <p className="text-lg leading-relaxed text-gray-700
                                    sm:text-base">
                            At TalentRanker.ai, we are passionate about matching the right talent for any job opening.
                            Our goal is to streamline the initial screening process while ensuring fair and objective candidate evaluation.
                            By streamlining the initial screening process, TalentRanker.ai allows hiring resources to focus on high-value
                            tasks such as in-depth interviews and candidate engagement.
                        </p>
                        <br />
                        <p className="text-lg leading-relaxed text-gray-700
                                    sm:text-base">
                            We believe in the power of technology to enhance human decision-making in the recruitment industry, not replace it.
                            And we would love to hear about you! You can reach us at
                            <span className="mx-1">
                                <a href="mailto:info@TalentRanker.ai" className="text-blue-500 underline">info@TalentRanker.ai</a>
                            </span>
                            with your feedback or for any further information.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;